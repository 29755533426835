@use '@angular/material' as mat;
@import '../../../assets/sass/variables';

.notification-success, .notification-information, .notification-warning, .notification-error {
	white-space: pre-wrap;
	color: $color-white;
}

.cdk-overlay-pane {
	pointer-events: none;
}

.cdk-overlay-pane > * {
	pointer-events: all;
}

.mat-snack-bar-container {
	position: relative;
	max-width: 100vw !important;
	border-radius: 0 !important;
	margin-top: 24px !important;
}

@for $i from 0 through 142 {
	html[data-scroll='#{$i}'] .mat-snack-bar-container {
		margin-top: (166 - $i * 1px) !important;
	}
}

@media only screen and (max-width: $bp-medium) {
	@for $i from 0 through 158 {
		html[data-scroll='#{$i}'] .mat-snack-bar-container {
			margin-top: (182 - $i * 1px) !important;
		}
	}
}

.mat-simple-snackbar-action .mat-button-wrapper {
	color: $color-white;
	font-size: $font-size-normal;
}

.notification-success {
	box-shadow: none;
	background-color: $color-vvz-success;
}

.notification-information {
	box-shadow: none;
	background-color: $color-vvz-information;
}

.notification-warning {
	box-shadow: none;
	background-color: $color-vvz-warning;
}

.notification-error {
	box-shadow: none;
	background-color: $color-vvz-error;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
	background-color: #2362a2;
}

.mat-radio-button.mat-accent {
	&.mat-radio-checked {
		.mat-radio-outer-circle {
			border-color: #2362a2;
		}
	}
}

.mat-radio-group {
	display: flex;
	flex-direction: row;
	gap: 50px;

	.mat-radio-button {
		display: inherit;
	}
}

.registraceKrokDva > * {
	overflow-x: unset;
	margin-bottom: 10px;
}


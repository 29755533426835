@import "variables";

.gov-modal__header {
	display: flex;
	place-content: space-between;
	padding: 1.875rem 3.125rem 0 3.125rem !important;
}

.mat-dialog-container {
	position: relative;
	box-shadow: none;
	padding: 0 !important;
	border-radius: 3px !important;

	.form-column-layout > * {
		overflow-x: unset;
	}

	h2 {
		font-size: 1.75rem;
		line-height: 1.4;
		margin-bottom: 1.5625rem;
		font-weight: 400;
	}

	.mat-dialog-content {
		margin: 0;
		padding: 1.875rem 3.125rem;

		p {
			margin-bottom: 1.5625rem;
		}

		label {
			font-size: 1rem;
		}
	}

	button {
		line-height: 1.5 !important;
		font-size: 0.75rem !important;
		padding: 0.625rem 1.25rem !important;
	}

	.mat-dialog-actions {
		min-height: unset;
		margin-bottom: 0;
		margin-top: 2rem;
		padding: 0;

		.mat-button-base + .mat-button-base {
			margin-left: 1.25rem;
		}
	}

	.mat-form-field-appearance-outline .mat-form-field-flex {
		padding: 0.5em 0.75em 0.5em 0.75em !important;
	}

	.mat-dialog-zavrit-modal {
		padding: 0.425rem 0.45rem !important;
	}
}

/*Responzivita*/

@media (max-width: $bp-small) {
	.cdk-overlay-pane {
		max-width: 95vw !important;
		width: 95vw !important;
	}

	.gov-modal__header {
		padding: 1.25rem 1.25rem 0 1.25rem !important;

		h2 {
			font-size: 1.625rem;
			line-height: 1.2;
		}
	}

	.mat-dialog-container {
		.mat-dialog-content {
			padding: 1.25rem !important;
		}
	}
}
